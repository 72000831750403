@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  padding: 0;
  margin: 0;
  font-family:
    -apple-system,
    BlinkMacSystemFont,
    Segoe UI,
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    Fira Sans,
    Droid Sans,
    Helvetica Neue,
    sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

/* Styles for Plyr audio, used in EWI */
.plyr--audio .plyr__controls {
  background-color: #f7feff !important;
  border: 2px solid #b0edf5 !important;
  border-radius: 5em !important;
  padding: 5px !important;
}

.plyr__volume {
  min-width: auto !important;
}
